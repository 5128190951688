import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ScrollService {
  private scrollSubject = new BehaviorSubject<number>(0);
  private customScrollSubject = new BehaviorSubject<number>(0);
  scroll$ = this.scrollSubject.asObservable();
  customScroll$ = this.customScrollSubject.asObservable();

  setScrollState(scrollPosition: number) {
    this.scrollSubject.next(scrollPosition);
  }

  scrollTo(distance: number): void {
    this.customScrollSubject.next(distance);
  }
}
